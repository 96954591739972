.register-left-div{
    flex: 0 0 35%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.register-right-div{
    flex: 0 0 65%;
    display: flex;
    align-items: center;
    background-color: var(--primary-color);
    justify-content: center;
}
@media only screen and (max-width: 1024px) {
    .main-div{
        flex-direction: column;
        height: auto;
    }
    
    .register-left-div{
        justify-content: center;
        padding-left: 0px;
    }
    .left-header-div{
        padding: 35px 0px;
    }

}

@media only screen and (max-width: 768px) {
    .main-div{
        height: auto;
    }
    
    .register-left-div{
        display: none;
    }
    
    .register-right-div{
        background-color: var(--primary-color);
    }
    .signup-card-wrapper{
        padding: 11px!important;
    }

    .signup-card{
        width: 400px!important;
    }
}

@media only screen and (max-width: 480px) {
    .main-div{
        height: auto;
    }
    .register-left-div{
        display: none;
    }
    .register-right-div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .signup-card{
        width: 300px!important;
        height: 100%!important;
        padding: 10px 20px!important;
    }
    .signup-card{
        border: none!important;
    }
}

@media only screen and (max-width: 400px) {
    .main-div{
        height: auto;
    }
    .signup-card{
        width: 250px!important;
        height: 100%!important;
        padding: 10px 20px!important;
    }
    .signup-card{
        border: none!important;
    }
    input{
        padding: 10px!important;
    }
    .sign-in{
        padding: 10px!important;
    }
}

.signup-card-wrapper{
    padding: 25px;
}

.signup-card{
    border: 1px solid white;
    padding: 20px 35px;
    background-color: #f5f5f5;
    border-radius: 10px;
    height: 100%;
    width: 600px;
}

.signup-header{
    text-align: center;
}

.signup-heading{
    font-size: 24px;
    font-weight: 600;
    color: var(--text-color);
}

.input-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px 0px;
    flex: 0 0 49%;
}

.register-label{
    color: var(--text-color);
    font-weight: 600;
    margin-top: 5px;
}