.package-row-breaker {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 100%;
}
.package-row-breaker .dash {
    width: 100%;
    height: 1px;
    fill: black; /* Adjust the color as desired */
}