@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply min-h-screen
    }
}

@layer components {
    .btn {
        @apply h-10 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold transition-all text-sm whitespace-nowrap
    }

    .btn-primary {
        @apply bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed
    }

    .btn-secondary {

    }

    .btn-secondary-outline {
        @apply border border-gray-200 text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed
    }

    .btn-success {
        @apply bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed
    }

    .btn-danger {
        @apply bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed
    }

    .btn-sm {
        @apply h-8 text-xs
    }

    .btn-lg {
        @apply h-12 text-lg
    }

    .form-label {
        @apply text-sm font-semibold text-gray-600 block
    }

    .form-label-sm {
        @apply text-xs font-semibold text-gray-600 block
    }

    .form-label-lg {
        @apply text-base font-semibold text-gray-600 block
    }

    .form-input {
        @apply h-10 px-2 rounded-md border duration-300 focus:outline-0 focus:border focus:ring-2 focus:ring-offset-1 focus:ring-blue-400 text-sm font-semibold text-gray-500 placeholder:font-light placeholder:text-gray-400 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-gray-400
    }

    .form-input-icon {
        @apply h-10 pr-2 rounded-md border duration-300 focus:outline-0 focus:border focus:ring-2 focus:ring-offset-1 focus:ring-blue-400 text-sm font-semibold text-gray-500 placeholder:font-light placeholder:text-gray-400 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-gray-400;
        padding-left: 2rem !important;
    }

    .form-select {
        @apply h-10 px-2 rounded-md border duration-300 focus:outline-0 focus:border focus:ring-2 focus:ring-offset-1 focus:ring-blue-400 text-sm font-semibold text-gray-500 placeholder:font-light placeholder:text-gray-400 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-gray-400
    }

    .form-textarea {
        @apply p-2 rounded-md border duration-300 focus:outline-0 focus:border focus:ring-2 focus:ring-offset-1 focus:ring-blue-400 text-sm font-semibold text-gray-500 placeholder:font-light placeholder:text-gray-400 disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-gray-400
    }

    .primary-table {
        @apply min-w-full divide-y divide-gray-200;
    }

    .primary-table-header-row {
        @apply bg-gray-50;
    }

    .primary-table-header-data {
        @apply px-6 py-3 text-left text-xs font-semibold text-gray-500;
    }

    .primary-table-body {
        @apply divide-y divide-gray-200;
    }

    .primary-table-body-data {
        @apply px-6 py-4 align-middle whitespace-nowrap text-gray-500;
    }

}

@layer utilities {

}

:root {
    /* Primary Colors */
    --primary-color: #6366f1;
    --primary-color-light: #8e90ef;
    /*  --primary-color-dark: #0062cc; */

    /* Text Colors */
    --text-color: #333;
    --text-color-light: #fff;
    --text-color-dark: #000;
    --text-hover-color: darkblue;

    /* Background Colors */
    --bg-color-card: #f5f5f5;
    --bg-color: #f5f5f5;
    --bg-color-dark: #374151;

    /* Border Colors */
    --border-color-light: lightgray;

    /* Danger Color */
    --danger-color: #F32013;

    /* Success Color */
    --success-color: #22c55e;
}

body {
    font-family: sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
}

.row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.main-container {
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    flex-grow: 1;
    background-color: var(--bg-color);
}

.main-content {
    flex-grow: 1;
    margin: 20px;
}

.card-container {
    display: none;
}

@media only screen and (max-width: 2560px) {
    .tabs-nav {
        height: 50px;
    }

    .tabs-nav .selected {
        padding: 13px 15px;
    }

    .tabs-nav a {
        margin-right: 10px;
        padding-left: 15px;
    }

    .primary-button, .disabled-button {
        height: 50px;
        width: 175px;
    }
}

@media only screen and (max-width: 768px) {
    .row {
        display: block;
    }

    .primary-button, .disabled-button {
        height: 50px;
        width: 100px;
    }
}

@media only screen and (max-width: 768px) {
    .body {
        font-size: 12px;
    }

    .content {
        flex-direction: column;
    }

    .main-content {
        height: 100%;
        min-height: 100vh;
    }

    .listing-container {
        display: none;
    }

    .card-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 20px;
    }

    .card {
        width: 100%; /* modified width */
        margin-bottom: 10px;
        padding: 20px;
        background-color: #fff;
        /* border: 1px solid #ccc; */
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
    }

    .card-body {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
    }

    .card h5 {
        margin-top: 0;
        margin-bottom: 5px;
    }

    .card h6 {
        margin-top: 0;
        margin-bottom: 5px;
    }

    .card p {
        margin: 0;
        font-size: 14px;
    }

    .card .actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }

    .card .actions button {
        margin-left: 10px;
        padding: 5px 10px;
        border: none;
        border-radius: 3px;
        background-color: var(--primary-color);
        color: #fff;
        cursor: pointer;
    }
}

@media only screen and (max-width: 480px) {
    body {
        font-size: 14px;
    }

    .card-body {
        font-size: 10px;
    }

    .card p {
        font-size: 10px;
    }

    .tabs-nav {
        height: 35px;
        padding: 0px 3px;
        gap: 15px;
        overflow-x: auto;
    }

    .tabs-nav a {
        text-decoration: none;
        padding: 0px;
        margin: 0px;
        font-weight: 600;
    }

    .tabs-nav .selected {
        padding: 10px 6px;
    }

    .primary-button, .disabled-button {
        height: 30px;
        width: 50px;
    }
}

/* Button */
.primary-button {
    background-color: var(--primary-color);
    color: var(--text-color-light);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    min-width: fit-content;
    padding: 5px;
}

.disabled-button {
    color: var(--text-color-light);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    padding: 5px;
    cursor: not-allowed;
    background: lightgray;
}

/* Generic Tab Class */
.tabs-nav {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0px 1px var(--border-color-light);
    white-space: nowrap;
}

.tabs-nav a {
    text-decoration: none;
    display: inline-block;
}

.tabs-nav .selected {
    font-weight: 600;
    background-color: var(--primary-color);
    color: var(--text-color-light);
}

/* Generic Tab Class End */

/* Generic Table Class */
.listing-container {
    max-width: 100%;
    overflow-y: auto;
    margin-top: 5px;
    max-height: calc(100vh - 230px);
    background-color: inherit;
    padding: 0px 15px;
}

.listing {
    width: 100%;
    background-color: inherit;
    color: inherit;
    border-collapse: initial;
    border-spacing: 0px 10px;
}

.listing th,
td {
    text-align: left;
    vertical-align: top;
    padding: 15px 0px;
}

.listing td {
    vertical-align: middle;
    /* border-top: 1px solid var(--border-color-light); */
    /* border-bottom: 1px solid var(--border-color-light); */
}

.lisiting-head-tr th {
    font-weight: bold;
}

.listing-body td a {
    color: var(--primary-color);

}

.listing-body td a:hover {
    cursor: pointer;
}

.listing-head, .listing-body tr {
    background-color: white !important;
}

.listing td:first-child,
th:first-child {
    padding-left: 10px;
}

.listing td:first-child {
    /* border-left: 1px solid var(--border-color-light); */
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.listing td:last-child {
    /* border-right: 1px solid var(--border-color-light); */
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

/* Generic Table Class End */

.form-wrapper {
    border: 1px solid var(--border-color-light);
    padding: 10px 10px 20px 10px;
    background: white;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
}
