.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
  
.modal {
    position: relative;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
}
  
.close-button:hover {
    color: var(--primary-color);
}
  
.modal-title{
    font-weight: 600;
    background: var(--primary-color);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding-left: 10px;
    color: white;
    padding-right: 10px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.modal-footer{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: right;
    border-top: 1px solid #d0cfcf;
    background: #f3f3f3;
    width: 100%;
    height: 60px;
    padding-right: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.modal-footer span{
    border: 1px solid var(--primary-color);
    padding: 5px 15px;
    border-radius: 20px;
    color: var(--primary-color);
}

.modal-footer .filled{
    border: 1px solid var(--primary-color);
    padding: 5px 15px;
    background-color: var(--primary-color);
    border-radius: 20px;
    color: var(--text-color-light);
}
.modal-footer span:hover{
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
}

.modal-optional-component{
    width: -webkit-fill-available;
}

@media only screen and (max-width: 2560px){
    .modal{
        min-height: 220px;
        min-width: 23%;
    }
}

@media only screen and (max-width: 1960px){
    .modal{
        min-height: 220px;
        min-width: 40%;
    }
}

@media only screen and (max-width: 1440px){
    .modal{
        min-height: 220px;
        min-width: 40%;
    }
}

@media only screen and (max-width: 1024px){
    .modal{
        min-height: 200px;
        min-width: 45%;
    }
}

@media only screen and (max-width: 768px){
    .modal{
        min-height: 180px;
        min-width: 60%;
    }
}

@media only screen and (max-width: 480px){
    .modal{
        min-height: 150px;
        min-width: 80%;
    }
}