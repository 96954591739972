/* .fc {
    max-height: calc(100vh - 105px);
    position: relative;
} */

.fc-list-empty {
    @apply bg-gray-50 !important;
}

.fc-list-event-time {
    @apply hidden
    
}
.fc-daygrid-event {
    @apply whitespace-normal;
}

.fc-theme-standard .fc-list-day-cushion {
    @apply bg-gray-50 !important;
}

.fc-list-day-text {
    @apply text-gray-600
}

.fc-list-day-side-text {
    @apply text-gray-600
}

.fc-event-time {
    @apply text-base text-black font-semibold whitespace-normal
}

.fc-event-title {
    @apply text-sm text-black whitespace-normal
}

.fc-header-toolbar {
    /*@apply bg-blue-500 text-white px-6 py-3;*/
 }

.fc-header-toolbar, .fc-toolbar, .fc-toolbar-ltr {
    margin: 0 !important;
}

.fc-view-harness {
    background: #fff;
} 

.fc-daygrid-body-unbalanced {
    width: 100% !important;
}

/* .fc-event {
    color: #fff;
    cursor: pointer;
    min-height: 35px;
    background-color: #fff;
    border: none;
} */

.event-primary {
    border-radius: 2px;
    width: 100%;
    
    /*padding: 6px;*/
}
.fc-list-event-time {
    @apply text-sm text-gray-500
}

/* .fully-booked-event {
    @apply bg-orange-400;
}

.booked-event {
    @apply bg-green-700;
}

.available-event {
    @apply bg-green-500;
} */

.fc-button-active {
    @apply bg-blue-700 text-white hover:bg-blue-700 !important;
}

.fc-toolbar-title {
    @apply md:text-2xl text-lg text-gray-500 !important
}

.fc .fc-toolbar {
    @apply block
}

.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) {
    @apply flex justify-end mb-2;
}

.fc .fc-toolbar .fc-toolbar-chunk:nth-child(1) .fc-button {
    @apply bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 border-0 disabled:opacity-50 disabled:cursor-not-allowed
}

.fc .fc-toolbar .fc-toolbar-chunk:nth-child(2) {
    @apply flex justify-between items-center border h-12;
}

.fc .fc-toolbar .fc-toolbar-chunk:nth-child(2) .fc-button {
    @apply bg-white md:px-6 px-4 h-full text-gray-700 border-0 first:border-r last:border-l border-gray-300 rounded-none focus:ring-0
}

.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) {
    @apply flex justify-between border-x;
}

.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button {
    @apply w-full h-12 bg-white hover:bg-gray-100 text-gray-500 text-sm capitalize border-0 focus:ring-0 rounded-none !important;
}

.fc .fc-toolbar .fc-toolbar-chunk:nth-child(3) .fc-button-active {
    @apply text-gray-700 border-b-2 border-gray-400 !important;
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0;
}

.fc .fc-timegrid-slot {
    height: 3rem;
}

/* .fc-timegrid-event-harness > .fc-timegrid-event {
    position: unset;
} */



@media only screen and (max-width: 2560px) {
    .fc {
        min-height: calc(100vh - 105px);
    }
}

@media only screen and (max-width: 1960px) {
    .fc {
        min-height: calc(100vh - 105px);
    }
}

@media only screen and (max-width: 1440px) {
    .fc {
        min-height: calc(100vh - 105px);
    }
}

@media only screen and (max-width: 1024px) {
    .fc {
        min-height: calc(100vh - 105px);
    }
}

@media only screen and (max-width: 768px) {
    .fc {
        min-height: calc(100vh - 105px);
    }

    /*.fc-toolbar .fc-header-toolbar {*/
        /*display: flex;*/
        /*flex-direction: column;*/
        /*gap: 5px*/
    /*}*/

    /*.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {*/
        /*order: 1;*/
        /*width: 100%;*/
        /*display: flex;*/
        /*justify-content:space-between;*/
        /*align-items: center;*/
    /*}*/

    /*.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {*/
        /*order: 2;*/
    /*}*/

    /*.fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {*/
        /*order: 3;*/
        /*width: 100%;*/
        /*display: flex;*/
        /*justify-content: end;*/
    /*}*/

    /*.fc-button-group {*/
        /*gap: 5px;*/
    /*}*/
}

@media only screen and (max-width: 480px) {
    .fc {
        min-height: calc(100vh - 75px);
    }
    .fc .fc-toolbar-title {
        font-size: 4.5vw;
    }
    .fc-timegrid-event .fc-event-time {
        font-size: 16px;
        white-space: normal;
        font-weight: 600 !important;
    }
    .fc .fc-timegrid-slot-label-cushion {
        font-size: 18px;
    }
    .fc-v-event .fc-event-title {
        font-size: 0.75rem;
    }
    .fc-event-time {
        font-size: 12px;
        white-space: normal;
        font-weight: 600 !important;
    }

    .fc-event-title {
        font-size: 14px !important;
        font-weight: 500 !important;
        letter-spacing: 0.5px;
        white-space: normal;
    }
    /*.fc-button{*/
    /*     white-space: nowrap;*/
    /*}*/
}

