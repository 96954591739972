#userDropdown{
    position: absolute;
}
#notificationDropdown{
    position: fixed;
    right: 125px;
    width: 350px;
}

#sidebarDropdown {
    position: absolute;
    left: -100%;
    transition: left 1.9s ease-out;
}
  
#sidebarDropdown.show {
    left: 0%;
}

#userDropdownButton{
    color: var(--text-color);
}
.main-navbar{
    background: #fff;
    border-bottom: #8080805e 1px solid;
    box-shadow: 0px 1px lightgray;
}
@media only screen and (max-width: 2560px) {
    #userDropdown{
        margin-top: 50px;
    }
    #notificationDropdown{
        margin-top: 50px;
    }
    .user-dropdown-align{
        margin-left: 0px;
    }
}

@media only screen and (max-width: 768px) {
    .main-navbar{
        display: none;
    }
    #userDropdown{
        margin-top: 45px;
    }
    /*#notificationDropdown{*/
    /*    display: none;*/
    /*}*/
    #sidebarDropdown{
        margin-top: 2rem;
        height: 100vh;
    }
    .user-dropdown-align{
        margin-left: -25px
    }
}