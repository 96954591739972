.main-div{
    display: flex;
    min-height: 100vh;
}

.left-div{
    flex: 0 0 55%;
    display: flex;
    align-items: center;
    padding-left: 150px;
}

.right-div{
    flex: 0 0 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
}

@media only screen and (max-width: 991px) {
    .main-div{
        display: block;
    }
    
    .left-div{
        justify-content: center;
        padding-left: 0px;
        height: 30vh;
    }
    .right-div{
        height: 70vh;
    }
    .left-header-div{
        padding: 35px 0px;
    }

}

@media only screen and (max-width: 767px) {
    .main-div{
        flex-direction: row !important;
        height: 100%;
        width: 100%;
    }
    
    .left-div{
        display: none;
    }
    
    .right-div{
        height: 100vh;
        background-color: var(--primary-color);
    }

    .login-card-wrapper{
        padding: 11px!important;
    }
}

@media only screen and (max-width: 480px) {
    .main-div{
        height: auto;
    }
    
    .left-div{
        display: none;
    }
    .right-div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .login-card{
        width: 300px!important;
        height: 100%!important;
        padding: 20px!important;
    }
    .login-card{
        border: none!important;
    }
}

@media only screen and (max-width: 400px) {
    .main-div{
        height: auto;
    }
    
    .login-card{
        width: 250px!important;
        height: 100%!important;
        padding: 10px!important;
    }
    .login-card{
        border: none!important;
    }
    input{
        padding: 10px!important;
    }
    .sign-in{
        padding: 10px!important;
    }
}

.login-card-wrapper{
    display: flex;
    align-items: center;
    flex: 0 0 50%;
    justify-content: center;
    padding: 35px;
}

.login-card{
    border: 1px solid white;
    padding: 35px;
    background-color: #f5f5f5;
    border-radius: 10px;
    height: fit-content;
    width: 400px;
}

.login-header{
    text-align: center;
}

.login-heading{
    font-size: 24px;
    font-weight: 600;
    color: var(--text-color);
}

.heading{
    color: var(--text-color);
    font-size: 0.875rem;
    font-weight: 700;
}

.row-break{
    margin-top: 1.5rem;
    border-bottom: 1px solid #CBD5E0;
}

.left-header-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.company-name{
    font-size: 50px;
    color:var(--primary-color);
}

.info{
    font-weight: bolder;
    font-size: 20px;
}

.label{
    color: var(--text-color);
    font-weight: 600;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.input {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: var(--text-color);
    background-color: #fff;
    border-radius: 0.25rem;
    border: 1px solid var(--border-color-light);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    outline: none;
    transition-timing-function: linear;
    transition-property: all;
    transition-duration: 150ms;
    width: -webkit-fill-available;
}

.input::placeholder {
    color: #CBD5E0;
}

.input:focus {
    box-shadow: 0 0 0 2px rgba(96, 165, 250, 0.5);
}

.input:focus::placeholder {
    color: #a0aec0;
}

.sign-in {
    background-color: var(--primary-color);
    color: #fff;
    font-weight: 700;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-radius: 2.25rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    outline: none;
    transition-timing-function: linear;
    transition-property: all;
    transition-duration: 150ms;
    width: -webkit-fill-available;
}

.sign-in:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.306);
}

.sign-in:active {
    background-color: #4B5563;
}

.sign-in:focus {
    box-shadow: 0 0 0 2px rgba(96, 165, 250, 0.5);
}

.credentials-wrapper{
    margin: 10px 0px;
}

.input-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 15px 0px;
}

.no-account-div{
    margin: 5px 0px;
    text-align: center;
}

.signup-link{
    color: var(--primary-color);
    cursor: pointer;
}

.remember-me-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.remember-me{
    font-weight: 600;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.forgot-password-link{
    font-weight: 600;
    color: var(--primary-color);
    cursor: pointer;
}

.select-user{
    width: 100%;
    padding: 10px;
    text-align: left;
    border-radius: 0.25rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    outline: none;
}