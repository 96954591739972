.btn-invite-student{
    text-align: right;
}

.btn-invite-student span{
    color: var(--text-color-light);
    background-color: var(--primary-color);
    padding: 10px 10px;
}

.invite-student-form{
    width: fit-content;
}
.invite-student-form .sign-in-div{
    text-align: center;
}
.invite-student-form .sign-in-div button{
    width: fit-content;
}

.students-searchbar{
    border: 1px solid var(--border-color-light);
    border-radius: 20px!important;
}

.invite-student-th{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
}

.activate-student-user-btn{
    text-align: right; 
    color: var(--primary-color); 
}

.deactivate-student-user-btn{
    text-align: right; 
    color: var(--danger-color);
}


.my-students-search-input{
    width: -webkit-fill-available;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #ccd6e1;
    background-color: #fff;
    border-radius: 20px;
    font-weight: 600;
    border: 1px solid var(--border-color-light);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    outline: none;
    transition-timing-function: linear;
    transition-property: all;
    transition-duration: 150ms;
}

@media only screen and (max-width: 2560px){
    .school-students-input-wrapper{
        width: 50%;
    }
    .request-student-th{
        width: 50%;
    }
    .my-students-th{
        width: 50%;
    }
}

@media only screen and (max-width: 768px){
    .school-students-input-wrapper{
        width: 70%;
    }
    .request-student-th{
        width: 70%;
    }
    .my-students-th{
        width: 70%;
    }
}

@media only screen and (max-width: 480px){
    .school-students-input-wrapper{
        width: 75%;
    }
    .request-student-th{
        width: 75%;
    }
    .req-school-input-wrapper{
        width: 75%;
    }
    .my-students-th{
        width: 25%;
    }
    .my-students-search-input{
        padding: 10px 5px;
    }
}

@media only screen and (max-width: 375px){
    .school-students-input-wrapper{
        width: 100%;
    }
    .req-school-input-wrapper{
        width: 75%;
    }
    .request-student-th{
        width: 80%;
    }
    .my-students-th{
        width: 20%;
    }
}