.main-sidebar {
    width: 13rem;
    background-color: #fff;
    height: calc(100vh - 65px);
    box-shadow: 1px 0px lightgray;
}

.main-sidebar .sidebar-user-dropdown{
    display: none;
}
  
.main-sidebar .toggle-button {
    display: none;
}
  
.main-sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
  
.main-sidebar ul a {
    text-decoration: none;
    text-align: center;
}
  
.main-sidebar ul .selected{
    background-color: var(--primary-color);
    color: var(--text-color-light);
}

.main-sidebar ul a:hover{
    background-color: var(--primary-color-light);
    color: var(--text-color-light);
}


.sidebar-link{
    padding-left: 1rem;
    display: flex;
    gap: 10px;
}

@media only screen and (max-width: 2560px){
    .main-sidebar{
        padding-top: 20px;
    }
    .main-sidebar ul li {
        margin-bottom: 10px;
        padding: 20px 0px;
    }
    .sidebar-options-dropdown{
        display: none;
    }
    .sidebar-link{
        height: 65px;
    }
}

@media only screen and (max-width: 768px){
    .main-sidebar{
        padding: 0px;
    }
    .main-sidebar ul {
        display: flex;
        gap: 15px;
    }
    .sidebar-options-dropdown{
        display: block;
    }
    .main-sidebar ul {
        display: none;
    }
    .main-sidebar{
        height: 3rem;
        width: -webkit-fill-available;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .main-sidebar .sidebar-user-dropdown{
        display: block;
        margin-right: 0px;
        color: var(--text-color-light);
    }
    .sidebar-link{
        height: fit-content;
    }
}